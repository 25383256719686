define("discourse/plugins/discourse-gradle-issue/gradle-issue/connectors/topic-title/gradle-issue-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      const tags = args.model.get("tags") || [];
      const bugTag = tags.find(t => /^gradle\-\d+$/i.test(t));
      if (bugTag) {
        component.set("gradleBugId", bugTag);
        component.set("gradleBugUrl", `http://issues.gradle.org/browse/${bugTag}`);
      }
    }
  };
});